<template>
  <div
    id="mobile_staff_filter"
    ref="mobile_staff_filter"
    class="offcanvas offcanvas-right"
    v-click-outside="handleOutsideClick"
  >
    <button id="mobile_staff_filter_toggle" style="display: none;"></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h4 class="font-weight-bold m-0">
        {{ $t("mobileStaffFilter.title") }}
      </h4>
      <a
        href="#"
        class=""
        id="mobile_staff_filter_close"
        @click="closeOffcanvas('mobile_staff_filter')"
      >
        <i class="ki ki-close icon-md"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative;"
    >
      <div class="d-flex justify-content-between align-items-center mb-7">
        <b-button
          v-b-toggle.collapse-places-filter
          class="m-1 bb-colapse mobile-places-filter-collapse"
          @click="placesIsOpen != placesIsOpen"
        >
          <div class="d-flex align-items-center">
            <i class="mr-7" :class="placesArrowClass"></i>
            <span>{{ $t("mobileStaffFilter.placesLabel") }}</span>
          </div>
        </b-button>
        <button class="pl-8" @click="createPlace()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </button>
      </div>
      <b-collapse v-model="placesIsOpen" id="collapse-places-filter">
        <div class="places-container">
          <div
            class="place-item"
            v-for="place in allPlaces"
            :key="place.id"
            :class="place.id === currentPlace.id ? 'active' : ''"
            @click="setCurrentPlace(place)"
          >
            <i class="fas fa-store"></i>
            <span>{{ place.name }}</span>
          </div>
        </div>
      </b-collapse>
    </perfect-scrollbar>
    <!--end::Content-->
    <PlaceFormModal ref="placeFormModal" />
  </div>
</template>

<script>
import MobileStaffFilterLayout from "@/assets/js/layout/extended/mobile-staff-filter.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import { mapGetters } from "vuex";
import { SET_PLACE } from "@/core/services/store/places.module.js";
import PlaceFormModal from "@/view/layout/extras/modal/PlaceFormModal.vue";
import placeFormMixin from "@/core/mixins/placeFormMixin.js";
import { enableBodyScroll } from "body-scroll-lock";

export default {
  name: "MobileStaffFilter",
  components: { PlaceFormModal },
  mixins: [placeFormMixin],
  data() {
    return {
      placesIsOpen: true
    };
  },
  mounted() {
    // Init Mobile Staff Filter Panel
    MobileStaffFilterLayout.init(this.$refs["mobile_staff_filter"]);
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        this.close();
      }
    },

    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
      let targetElement = document.querySelector("#mobile_staff_filter");
      enableBodyScroll(targetElement);
    },

    /**
     * Close mobile staff filter element
     * @return {void}
     */
    close() {
      document.querySelector("#mobile_staff_filter_close").click();
    },

    /**
     * Set selected place in store places module
     * @param {Object} place
     * @return {void}
     */
    setCurrentPlace(place) {
      this.$store.dispatch(SET_PLACE, place);
    }
  },
  computed: {
    ...mapGetters(["allPlaces", "currentPlace"]),

    /**
     * Return class for places filter arrow
     * @return {String}
     */
    placesArrowClass() {
      if (this.placesIsOpen) {
        return "la la-angle-up";
      }

      return "la la-angle-down";
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/mobile-staff-filter.scss";
</style>
