<template>
  <div class="bb-place-filter">
    <BBDropdown ref="placesDropdown">
      <template v-slot:toggle>
        <div class="bb-place-filter-toggle">
          <span>{{ currentPlace.name }}</span>
          <i class="ki ki-arrow-down"></i>
        </div>
      </template>
      <template v-slot:items>
        <div
          class="place-filter-item"
          v-for="place in allPlaces"
          :key="place.id"
        >
          <div
            class="place-filter-item-present"
            @click="setCurrentPlace(place)"
          >
            <i class="fas fa-store"></i>
            <span>{{ place.name }}</span>
          </div>
          <div
            class="place-filter-item-actions"
            v-if="
              $hasRole(acl.roles.owner) ||
                $hasPermission(acl.permissions.manageAccount) ||
                $hasPermission(acl.permissions.managePlace)
            "
          >
            <i @click="editBasicInfo(place)" class="ki ki-more-hor"></i>
          </div>
        </div>
        <div
          class="place-filter-footer-item"
          @click="createPlace()"
          v-if="
            $hasRole(acl.roles.owner) ||
              $hasPermission(acl.permissions.manageAccount) ||
              $hasPermission(acl.permissions.managePlace)
          "
        >
          <i class="fas fa-plus"></i>
          <span>{{ $t("calendarFilters.placeFilter.createPlace") }}</span>
        </div>
      </template>
    </BBDropdown>
    <PlaceFormModal ref="placeFormModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_PLACE } from "@/core/services/store/places.module.js";
import BBDropdown from "@/view/content/BBDropdown.vue";
import PlaceFormModal from "@/view/layout/extras/modal/PlaceFormModal.vue";
import placeFormMixin from "@/core/mixins/placeFormMixin.js";

export default {
  name: "PlaceFilter",
  components: { BBDropdown, PlaceFormModal },
  mixins: [placeFormMixin],
  methods: {
    /**
     * Set selected place in store places module
     * @param {Object} place
     * @return {void}
     */
    setCurrentPlace(place) {
      this.$store.dispatch(SET_PLACE, place);
      this.$refs.placesDropdown.closeDropdown();
    }
  },
  computed: {
    ...mapGetters(["allPlaces", "currentPlace"])
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/place-filter.scss";
</style>
