var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bb-place-filter"},[_c('BBDropdown',{ref:"placesDropdown",scopedSlots:_vm._u([{key:"toggle",fn:function(){return [_c('div',{staticClass:"bb-place-filter-toggle"},[_c('span',[_vm._v(_vm._s(_vm.currentPlace.name))]),_c('i',{staticClass:"ki ki-arrow-down"})])]},proxy:true},{key:"items",fn:function(){return [_vm._l((_vm.allPlaces),function(place){return _c('div',{key:place.id,staticClass:"place-filter-item"},[_c('div',{staticClass:"place-filter-item-present",on:{"click":function($event){return _vm.setCurrentPlace(place)}}},[_c('i',{staticClass:"fas fa-store"}),_c('span',[_vm._v(_vm._s(place.name))])]),(
            _vm.$hasRole(_vm.acl.roles.owner) ||
              _vm.$hasPermission(_vm.acl.permissions.manageAccount) ||
              _vm.$hasPermission(_vm.acl.permissions.managePlace)
          )?_c('div',{staticClass:"place-filter-item-actions"},[_c('i',{staticClass:"ki ki-more-hor",on:{"click":function($event){return _vm.editBasicInfo(place)}}})]):_vm._e()])}),(
          _vm.$hasRole(_vm.acl.roles.owner) ||
            _vm.$hasPermission(_vm.acl.permissions.manageAccount) ||
            _vm.$hasPermission(_vm.acl.permissions.managePlace)
        )?_c('div',{staticClass:"place-filter-footer-item",on:{"click":function($event){return _vm.createPlace()}}},[_c('i',{staticClass:"fas fa-plus"}),_c('span',[_vm._v(_vm._s(_vm.$t("calendarFilters.placeFilter.createPlace")))])]):_vm._e()]},proxy:true}])}),_c('PlaceFormModal',{ref:"placeFormModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }