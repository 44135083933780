<template>
  <div id="staff_page_wrapper">
    <div class="bb-mobile-header">
      <div class="bb-mobile-header-left">
        <button class="mobile-sidebar-btn" @click="openMobileAside()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
      </div>
      <h1>{{ $t("staff.title") }}</h1>
      <div class="bb-mobile-header-right">
        <button @click="openMobileStaffFilter()">
          <i class="ki ki-more-ver"></i>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <PlaceFilter />
      <button
        class="bb-btn btn-success btn-lg new-staff-btn"
        @click="createCalendar"
      >
        {{ $t("staff.createStaffBtn") }}
      </button>
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items="currentPlace.staff"
      hide-default-footer
      :page.sync="pagination.page"
      :items-per-page="pagination.itemsPerPage"
      @page-count="pagination.pageCount = $event"
      @pagination="updatePaginationText($event)"
      ref="staffTable"
      class="bb-table staff-table"
    >
      <template v-slot:item.color="{ item }">
        <div
          class="bb-badge badge-sm badge-rounded"
          :style="'background: ' + item.calendar.color"
        ></div>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="table-action-buttons">
          <button
            class="table-action-button"
            @click="editWorkingHours(item.calendar)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-clock"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
          </button>
          <button class="table-action-button" @click="editCalendar(item)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-edit"
            >
              <path
                d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
              ></path>
              <path
                d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
              ></path>
            </svg>
          </button>
          <button class="table-action-button" @click="deleteStaff(item)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-trash-2"
            >
              <polyline points="3 6 5 6 21 6"></polyline>
              <path
                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
              ></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </button>
        </div>
      </template>
    </v-data-table>
    <div class="bb-table-footer">
      <v-pagination
        class="bb-pagination"
        v-model="pagination.page"
        :length="pagination.pageCount"
      ></v-pagination>
      <div class="d-flex justufy-content-between align-items-center">
        <BBSelect
          class="bb-pagination-per-page-select"
          :options="pagination.perPageOptions"
          v-model="pagination.itemsPerPage"
        />
        <span class="bb-table-pagination-text">
          {{ $t("staff.table.pagination.show") }}
          {{ pagination.currentFromItem }} - {{ pagination.currentToItem }}
          {{ $t("staff.table.pagination.of") }}
          {{ pagination.currentTotalItems }}
        </span>
      </div>
    </div>
    <div id="mobile_staff_wrapper">
      <div class="staff-list">
        <div
          class="staff-item"
          v-for="staffMember in currentPlace.staff"
          :key="staffMember.id"
          @click="showMobileActions(staffMember)"
        >
          <div class="d-flex justify-content-start align-items-center">
            <div
              class="bb-badge badge-sm badge-rounded"
              :style="'background: ' + staffMember.calendar.color"
              style="flex: 0 0 auto"
            ></div>
            <div class="staff-details">
              <span>{{ staffMember.fullName }}</span>
              <small>{{ staffMember.email }}</small>
            </div>
          </div>
          <i class="la la-angle-right"></i>
        </div>
      </div>
      <button class="mobile-new-calendar-btn" @click="createCalendar">
        <i class="la la-plus"></i>
      </button>
      <div
        id="mobile_actions_container"
        class="mobile-actions-container"
        v-show="mobileActionsVisible"
        v-click-outside="handleMobileActionsOutsideClick"
      >
        <div class="mobile-actions">
          <button
            class="mobile-action-btn"
            @click="
              editCalendar(selectedStaffMember);
              hideMobileActions();
            "
          >
            {{ $t("staff.mobileButtons.editStaffBtn") }}
          </button>
          <button
            class="mobile-action-btn"
            @click="
              editWorkingHours(selectedStaffMember.calendar);
              hideMobileActions();
            "
          >
            {{ $t("staff.mobileButtons.editWorkingHoursBtn") }}
          </button>
          <button
            class="mobile-action-btn danger"
            @click="
              deleteStaff(selectedStaffMember);
              hideMobileActions();
            "
          >
            {{ $t("staff.mobileButtons.deleteStaffBtn") }}
          </button>
        </div>
        <button class="mobile-action-btn" @click="hideMobileActions()">
          {{ $t("staff.mobileButtons.closeBtn") }}
        </button>
      </div>
    </div>
    <MobileStaffFilter />
    <CalendarFormModal ref="calendarFormModal" />
    <AvailabilityFormModal ref="availabilityFormModal" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import calendarFormMixin from "@/core/mixins/calendarFormMixin.js";
import calendarFormModalMixin from "@/core/mixins/calendarFormModalMixin.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";
import { mapGetters } from "vuex";
import PlaceFilter from "@/view/pages/components/Calendar/PlaceFilter.vue";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";
import MobileStaffFilter from "@/view/pages/components/Staff/MobileStaffFilter.vue";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import CalendarFormModal from "@/view/layout/extras/modal/CalendarFormModal.vue";
import AvailabilityFormModal from "@/view/layout/extras/modal/AvailabilityFormModal.vue";
import { EventBus } from "@/core/services/event-bus.js";

export default {
  name: "Staff",
  components: {
    PlaceFilter,
    BBSelect,
    MobileStaffFilter,
    CalendarFormModal,
    AvailabilityFormModal,
  },
  mixins: [calendarFormModalMixin, mobileAsideMixin],
  data() {
    return {
      tableHeaders: [
        {
          text: "",
          value: "color",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("staff.table.columns.name"),
          value: "fullName",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("staff.table.columns.phone"),
          value: "phone",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("staff.table.columns.email"),
          value: "email",
          align: "left",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          align: "right",
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        perPageOptions: [
          { text: "10", value: 10 },
          { text: "20", value: 20 },
          { text: "50", value: 50 },
        ],
        perPageDropdownIsOpen: false,
        currentFromItem: 0,
        currentToItem: 0,
        currentTotalItems: 0,
      },
      mobileActionsVisible: false,
      selectedStaffMember: null,
    };
  },
  mounted() {
    // Dispatch store SET_BREADCRUMB action to set page title
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("staff.title") }]);
    // Dispatch store GET_PLACES action to get places from api
    this.$store.dispatch(GET_PLACES);
  },
  methods: {
    /**
     * Update pagination text based on tables pagination event
     * @param {Object} event
     * @return {void}
     */
    updatePaginationText(event) {
      this.pagination.currentFromItem = parseInt(event.pageStart) + 1;
      this.pagination.currentToItem = event.pageStop;
      this.pagination.currentTotalItems = event.itemsLength;
    },

    /**
     * Set value for items per page in table
     * @param {Number} perPage
     * @return {void}
     */
    setPerPageItems(perPage) {
      this.pagination.itemsPerPage = perPage;
    },

    /**
     * Delete staff member
     * @param {Object} staffMember
     * @return {void}
     */
    deleteStaff(staffMember) {
      EventBus.$emit("deletingStaff", {
        staffMember: staffMember,
        successCallback: function () {
          this.$store.dispatch(GET_PLACES);
        },
      });
    },

    /**
     * Open mobile staff filter by imitating click on mobile_staff_filter_toggle element
     * @return {void}
     */
    openMobileStaffFilter() {
      document.querySelector("#mobile_staff_filter_toggle").click();
      let targetElement = document.querySelector("#mobile_staff_filter");
      disableBodyScroll(targetElement);
    },

    /**
     * Handle mobile actions outside click
     * @param {Object} event
     * @return {void}
     */
    handleMobileActionsOutsideClick(event) {
      if (
        event.target.classList.contains("offcanvas-overlay") &&
        this.mobileActionsVisible
      ) {
        this.hideMobileActions();
      }
    },

    /**
     * Show mobile service actions
     * @param {Object} staffMember
     * @return {void}
     */
    showMobileActions(staffMember) {
      this.selectedStaffMember = staffMember;
      this.mobileActionsVisible = true;
      this.$emit("toggle-overlay");
      let targetElement = document.querySelector("#mobile_actions_container");
      disableBodyScroll(targetElement);
    },

    /**
     * Hide mobile actions
     * @return {void}
     */
    hideMobileActions() {
      this.selectedStaffMember = null;
      this.mobileActionsVisible = false;
      this.$emit("toggle-overlay");
      let targetElement = document.querySelector("#mobile_actions_container");
      enableBodyScroll(targetElement);
    },
  },
  computed: {
    ...mapGetters(["currentPlace"]),
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/pages/staff.scss";
</style>
