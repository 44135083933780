<template>
  <b-modal
    id="availability_form_modal"
    ref="bvModal"
    :no-close-on-backdrop="true"
  >
    <template v-slot:modal-header="{ close }">
      <div class="modal-header-buttons-container">
        <div class="placeholder"></div>
        <h2>{{ $t("availabilityFormModal.title") }}</h2>
        <button
          class="close-modal-btn mr-7"
          id="availability_form_modal_close_btn"
          @click="close()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </template>
    <div class="bb-card">
      <div class="card-body">
        <div
          class="daily-working-hours"
          v-for="(dailyWorkingHours, key) in form.helpers.workingHours"
          :key="key"
        >
          <BBCheckbox
            v-model="dailyWorkingHours.on"
            :label="$t('weekdays.' + key)"
          />
          <div
            v-show="dailyWorkingHours.on"
            class="daily-working-hours-selects"
          >
            <div class="bb-form-group">
              <label>{{ $t("availabilityFormModal.fromInput.label") }}</label>
              <BBSelect
                :options="getTimeOptions(key)"
                v-model="dailyWorkingHours.hours[0].from"
                :placeholderHidden="true"
              />
            </div>
            <div class="bb-form-group">
              <label>{{ $t("availabilityFormModal.toInput.label") }}</label>
              <BBSelect
                :options="getTimeOptions(key, dailyWorkingHours.hours[0].from)"
                v-model="dailyWorkingHours.hours[0].to"
                :placeholderHidden="true"
              />
            </div>
          </div>
          <span
            class="daily-working-hours-closed"
            v-show="!dailyWorkingHours.on"
          >
            {{ $t("availabilityFormModal.closedLabel") }}
          </span>
        </div>
        <div class="bb-invalid-feedback" v-show="form.errors.workingHours">
          {{ $t("serviceFormModal.validation.error") }} 
        </div>
      </div>
    </div>
    <template v-slot:modal-footer="{ close }">
      <button class="bb-btn btn-outline" @click="close()">
        {{ $t("availabilityFormModal.closeBtn") }}
      </button>
      <button class="bb-btn btn-success" @click="submit()">
        {{ $t("availabilityFormModal.saveBtn") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import availabilityForm from "@/core/services/forms/availabilityForm.js";
import formMixin from "@/core/mixins/formMixin.js";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
import BBCheckbox from "@/view/content/BBForm/BBCheckbox.vue";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { GET_PLACES } from "@/core/services/store/places.module.js";

export default {
  name: "AvailabilityFormModal",
  mixins: [formMixin],
  components: { BBSelect, BBCheckbox },
  data() {
    return {
      minTime: 6,
      maxTime: 24
    };
  },
  beforeMount() {
    // Get form object from availabilityForm service
    this.form = JSON.parse(JSON.stringify(availabilityForm));
  },
  methods: {
    /**
     * Show the modal
     * @return {void}
     */
    show() {
      this.$refs.bvModal.show();
    },
    /**
     * Close the modal
     * @return {void}
     */
    closeModal() {
      document.querySelector("#availability_form_modal_close_btn").click();
    },

    /**
     * Return array of time options
     * @param {String} dayName
     * @param {String|null} startTime
     * @return {Array}
     */
    getTimeOptions(dayName, startTime = null) {
      let options = [];
      let minTime = this.minTime;
      let maxTime = this.maxTime;
      if (this.selectedPlace) {
        if (this.selectedPlace.openingHours[dayName].from) {
          let minTimeArray = this.selectedPlace.openingHours[
            dayName
          ].from.split(":");
          minTime = parseInt(minTimeArray[0]);
          let maxTimeArray = this.selectedPlace.openingHours[dayName].to.split(
            ":"
          );
          maxTime = parseInt(maxTimeArray[0]);
        } else {
          return options;
        }
      }
      if (startTime) {
        let startTimeArray = startTime.split(":");
        let startTimeHour = parseInt(startTimeArray[0]);
        if (parseInt(startTimeArray[1]) > 0) {
          startTimeHour++;
        }
        minTime = startTimeHour;
      }
      for (let i = minTime; i <= maxTime; i++) {
        let maxMins = maxTime === i ? 5 : 60;
        for (let j = 0; j < maxMins; j += 5) {
          let timeSlot =
            String(i).padStart(2, "0") + ":" + String(j).padStart(2, "0");
          let option = {
            text: timeSlot,
            value: timeSlot
          };
          options.push(option);
        }
      }

      return options;
    },

    /**
     * Update working hours fields
     * @return {void}
     */
    updateWorkingHoursFields() {
      for (let [day, value] of Object.entries(this.form.helpers.workingHours)) {
        this.form.fields.workingHours[day] = value.hours;
      }
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.updateWorkingHoursFields();
      this.submitForm()
        .then(response => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null
          });
          this.closeModal();
          this.$store.dispatch(GET_PLACES);
        })
        .catch(error =>
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message]
          })
        );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/modals/availability-form-modal.scss";
</style>
